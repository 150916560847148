svg > g[class^="raphael-group-"] > text{
  display: none;
}

#donut {
  margin-top: -15px !important;
  margin-left: -40px !important;
}

#donut-mobile {
  margin-top: -30px !important;
  /* margin-left: -7px !important; */
}
.CalendarDay__selected {
  background-color: #CCCCCC;
  border: 1px solid #CCCCCC;
  color: #333;
  font-weight: bold;
}

.CalendarDay__selected:hover {
  background-color: #E1E1E1;
  border: 1px solid #E1E1E1;
  color: #333;
  font-weight: bold;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #000;
}

.button-previous {
  width: 50%;
  background-color: #1976d2 !important;
  border-radius: 0px !important;
}

.button-next {
  border-radius: 0px !important;
  width: 50%;
  background-color: #1976d2 !important;
}

.buttons {
  width: 100%;
  /* margin-top: 10px; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.toolbar-video {
  width: 100%;
  height: 100%;
}
.header {
  padding-left: unset !important;
  padding-right: unset !important;
}
.logo-gergo {
  height: 65px;
  margin-top: 8px;
  margin-left: 45px;
}
.logo-gergo-app {
  display: none;
}

.youtube-icon {
  font-size: 30px !important;
}
.icon-video {
  margin-right: 20px;
  margin-top: 5px;
}

.title-video {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 30px;
  margin-bottom: 5px;
  font-family: arial, helvetica, sans-serif;
  font-style: italic;
  /* font-variant: small-caps; */
  font-weight: bold;

  /* font: italic small-caps bold 30px "Comic Sans MS", sans-serif; */
}

.container-videos {
  /* margin-top: 5%; */
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.disposition-video {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.react-player {
  max-width: 720px;
  max-height: 420px;
}

@media screen and (max-width: 983px) {
  .container-videos {
    margin-top: unset;
    -webkit-align-items: unset;
            align-items: unset;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: unset;
            justify-content: unset;
  }
  .title-video {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }
  .react-player {
    max-width: 640px !important;
    max-height: 362px !important;
  }
}

/* media queris max-width de 720 a 420*/

@media screen and (max-width: 870px) {
  .container-videos {
    margin-top: unset;
    -webkit-align-items: unset;
            align-items: unset;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: unset;
            justify-content: unset;
  }
  .title-video {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }
  .react-player {
    max-width: 550px !important;
    max-height: 314px !important;
  }
}

/* @media screen and (max-width: 683px) {
  .container-videos {
    margin-top: unset;
    align-items: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: unset;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }
  .react-player {
    max-width: 550px;
    max-height: 314px;
  }
} */

@media (min-width: 600px) {
  .header {
    padding-left: unset !important;
    padding-right: unset !important;
  }
}

@media screen and (max-width: 760px) {
  .container-videos {
    margin-top: unset;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .title-video {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }
  .react-player {
    max-width: 490px !important;
    max-height: 279px !important;
  }
}

@media screen and (max-width: 680px) {
  .container-videos {
    margin-top: unset;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .title-video {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }
  .react-player {
    max-width: 468px !important;
    max-height: 267px !important;
  }
}

@media screen and (max-width: 660px) {
  .toolbar-video {
    margin-left: -19px;
  }
  .logo-gergo {
    display: none;
  }
  .logo-gergo-app {
    display: block;
    height: 60px;
    margin-top: -2px;
  }
  .header {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .container-videos {
    margin-top: unset;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .title-video {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }

  .react-player {
    max-width: 420px !important;
    max-height: 239px !important;
  }
}

@media screen and (max-width: 490px) {
  .toolbar-video {
    margin-left: -19px;
  }
  .logo-gergo {
    display: none;
  }
  .logo-gergo-app {
    display: block;
    height: 60px;
    margin-top: -2px;
  }
  .header {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .container-videos {
    margin-top: unset;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .title-video {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }

  .react-player {
    max-width: 350px !important;
    max-height: 200px !important;
  }
}

/* fim media queris max-width de 720 a 420*/

/*media queris min-width 1000*/

@media screen and (min-width: 1700px) {
  .container-videos {
    margin-top: 3%;
  }
  .title-video {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 30px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 5px;
  }

  .youtube-icon {
    font-size: 30px !important;
  }

  .react-player {
    max-width: 1055px;
    max-height: 596px;
  }
}

@media screen and (min-width: 1900px) {
  .container-videos {
    margin-top: 3%;
  }
  .title-video {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 30px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 5px;
  }

  .youtube-icon {
    font-size: 30px !important;
  }

  .react-player {
    max-width: 1137px;
    max-height: 643px;
  }
}

@media screen and (min-width: 2000px) {
  .container-videos {
    margin-top: 3%;
  }
  .title-video {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 35px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 5px;
  }

  .youtube-icon {
    font-size: 35px !important;
  }

  .react-player {
    max-width: 1310px;
    max-height: 740px;
  }
}

@media screen and (min-width: 2200px) {
  .container-videos {
    margin-top: 3%;
  }
  .title-video {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 40px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 5px;
  }

  .youtube-icon {
    font-size: 40px !important;
  }

  .react-player {
    max-width: 1793px;
    max-height: 1012px;
  }
}

/* @media screen and (min-width: 3000px) {
  .termo-font {
    font-size: 42px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 4500px) {
  .termo-font {
    font-size: 56px !important;
    text-align: justify !important;
  }
} */

/*fim min-width 4500*/

.termo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  outline: none;
}

.termo-font {
  font-size: 13.5px !important;
  text-align: justify !important;
}

@media screen and (min-width: 1000px) {
  .termo-font {
    font-size: 15px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1700px) {
  .termo-font {
    font-size: 17px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1900px) {
  .termo-font {
    font-size: 18.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2000px) {
  .termo-font {
    font-size: 20.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2200px) {
  .termo-font {
    font-size: 28px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 3000px) {
  .termo-font {
    font-size: 42px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 4500px) {
  .termo-font {
    font-size: 56px !important;
    text-align: justify !important;
  }
}

#container-circles{position:absolute;left:50%;bottom:90px}

#outer-circle{left:-37px;height:75px;width:75px;background-color:hsla(0,0%,100%,.4);z-index:1}

#inner-circle,#outer-circle{position:absolute;border-radius:50%}
#inner-circle{left:50%;top:38px;height:44px;width:44px;background:#fff;margin:-22px 0 0 -22px;z-index:2}
#inner-circle.is-clicked{height:38px;width:38px;margin:-19px 0 0 -19px}
#white-flash.normal{position:absolute;height:100%;width:100%;opacity:1;transition:opacity .9s ease-out}
#white-flash.do-transition{opacity:0;background:#fff}
#display-error{color:#000;background-color:#fff}.react-html5-camera-photo{position:relative;text-align:center}.react-html5-camera-photo>img,.react-html5-camera-photo>video{width:275px;   border: 1px solid rgba(0, 0, 0, 0.5); border-radius: 4px;}.react-html5-camera-photo>.display-error{width:400px;margin:0 auto}@media(max-width:275px){.react-html5-camera-photo>.display-error,.react-html5-camera-photo>img,.react-html5-camera-photo>video{width:100%}}.react-html5-camera-photo-fullscreen>img,.react-html5-camera-photo-fullscreen>video{width:100vw;height:100vh}.react-html5-camera-photo-fullscreen>video{object-fit:fill}.react-html5-camera-photo-fullscreen>.display-error{width:100vw;height:100vh}
/*# sourceMappingURL=index.css.map*/

.video-mobile {
  display: none;
}

.img-example {
  width: 100%;
  height: 100%;
}

.menu-lateral {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #fafafa;
  width: 26vw;
  height: 80vh;
}

.container-video {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.group-video {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 10vh;
}

.menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.list-item {
  margin-left: 10px;
  margin-top: -3px;
}

.list-menu {
  width: 23vw;
}

@media screen and (max-width: 680px) {
  .menu-lateral {
    width: 10vw;
    height: 55vh;
  }
  .list-item {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .menu-lateral {
    display: none;
  }
  .list-item {
    display: block;
  }

  .menu-item {
    margin-top: 30px !important;
  }

  .video-not-mobile {
    display: none;
  }

  .video-mobile {
    display: block;
  }

  .video-disabled {
    pointer-events: none;

    /* for "disabled" effect */
    opacity: 0.5;
    background: #ccc;
  }

  .video-not-disabled {
    display: block;
  }
}

.termo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  outline: none;
}

.termo-font {
  font-size: 13.5px !important;
  text-align: justify !important;
}

@media screen and (min-width: 1000px) {
  .termo-font {
    font-size: 15px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1700px) {
  .termo-font {
    font-size: 17px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1900px) {
  .termo-font {
    font-size: 18.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2000px) {
  .termo-font {
    font-size: 20.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2200px) {
  .termo-font {
    font-size: 28px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 3000px) {
  .termo-font {
    font-size: 42px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 4500px) {
  .termo-font {
    font-size: 56px !important;
    text-align: justify !important;
  }
}

.video-mobile {
  display: none;
}

.img-example {
  width: 100%;
  height: 100%;
}

.menu-lateral {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #fafafa;
  width: 26vw;
  height: 80vh;
}

.container-video {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.group-video {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 10vh;
}

.menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.list-item {
  margin-left: 10px;
  margin-top: -3px;
}

.list-menu {
  width: 23vw;
}

@media screen and (max-width: 680px) {
  .menu-lateral {
    width: 10vw;
    height: 55vh;
  }
  .list-item {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .menu-lateral {
    display: none;
  }
  .list-item {
    display: block;
  }

  .menu-item {
    margin-top: 30px !important;
  }

  .video-not-mobile {
    display: none;
  }

  .video-mobile {
    display: block;
  }

  .video-disabled {
    pointer-events: none;

    /* for "disabled" effect */
    opacity: 0.5;
    background: #ccc;
  }

  .video-not-disabled {
    display: block;
  }
}

/* .player-wrapper {
    position: relative;
    padding-top: 56.25%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
} */

.react-player {
    max-width: 720px;
    max-height: 420px;
}

@media screen and (max-width: 719px) {
    .react-player {
        max-width: 520px;
        max-height: 350px;
    }   
}

@media screen and (max-width: 519px) {
    .react-player {
        max-width: 420px;
        max-height: 290px;
    }   
}

@media screen and (max-width: 419px) {
    .react-player {
        max-width: 350px;
        max-height: 200px;
    }   
}
.MuiInput-underline {
  content: none !important;
  right: none !important;
  bottom: none !important;
}
.MuiInput-underline:after {
  content: none !important;
  right: none !important;
  bottom: none !important;
}

.MuiInput-underline:before {
  content: none !important;
  right: none !important;
  bottom: none !important;
}

.termo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  outline: none;
}

.container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0vw 3vw;
}
.termo-font {
  font-size: 13.5px !important;
  text-align: justify !important;
}

@media screen and (min-width: 1000px) {
  .termo-font {
    font-size: 15px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1700px) {
  .termo-font {
    font-size: 17px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1900px) {
  .termo-font {
    font-size: 18.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2000px) {
  .termo-font {
    font-size: 20.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2200px) {
  .termo-font {
    font-size: 28px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 3000px) {
  .termo-font {
    font-size: 42px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 4500px) {
  .termo-font {
    font-size: 56px !important;
    text-align: justify !important;
  }
}

.img-example {
  width: 100%;
  height: 100%;
}

.MuiInput-underline {
  content: none !important;
  right: none !important;
  bottom: none !important;
}
.MuiInput-underline:after {
  content: none !important;
  right: none !important;
  bottom: none !important;
}

.MuiInput-underline:before {
  content: none !important;
  right: none !important;
  bottom: none !important;
}

.termo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  outline: none;
}

.container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0vw 3vw;
}
.termo-font {
  font-size: 13.5px !important;
  text-align: justify !important;
}

@media screen and (min-width: 1000px) {
  .termo-font {
    font-size: 15px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1700px) {
  .termo-font {
    font-size: 17px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1900px) {
  .termo-font {
    font-size: 18.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2000px) {
  .termo-font {
    font-size: 20.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2200px) {
  .termo-font {
    font-size: 28px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 3000px) {
  .termo-font {
    font-size: 42px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 4500px) {
  .termo-font {
    font-size: 56px !important;
    text-align: justify !important;
  }
}

.img-example {
  width: 100%;
  height: 100%;
}

.content-quiz {
  /* position: relative; */
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  /* justify-content: center; */
  height: 100%;
  /* align-items: center; */
  /* left: 60%; */
  padding-right: 12%;
}

.content-grid {
  /* position: absolute; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* max-width: 350px;
  width: 100%;
  height: 100%;
  justify-content: center; */
}

.container-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 410px;

  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

.container-squad {
  background-color: #009de0;
  border-radius: 10px;
  max-height: 595px;
  padding: 30px 30px 30px 30px;
}

/* .MuiInputLabel-outlined {
  transform: translate(14px, 13px) scale(1) !important;
}

.MuiButton-containedSecondary {
  background-color: #0c8bbf !important;
}

.MuiInputBase-input {
  height: 4px !important;
} */

.MuiInputLabel-outlined .MuiInputLabel-marginDense {
  color: #fff !important;
}

.MuiInputBase-root {
  font-size: unset;
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
} */

/* 
.MuiInputBase-input {
  height: 0px !important;

} */

.MuiInput-underline {
  content: none !important;
  right: none !important;
  bottom: none !important;
}
.MuiInput-underline:after {
  content: none !important;
  right: none !important;
  bottom: none !important;
}

.MuiInput-underline:before {
  content: none !important;
  right: none !important;
  bottom: none !important;
}

.termo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  outline: none;
}

.container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0vw 3vw;
}
.termo-font {
  font-size: 13.5px !important;
  text-align: justify !important;
}

.title-squad {
  max-width: 250px;
  font-size: 18px;
  font-weight: 900;
  /* font-weight: bold; */
  color: #fff;
  padding-bottom: 10px;
}

.content-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
}

.down {
  background-color: #f3b409 !important;
}

.btn-down {
  max-width: 240px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.span-btn {
  color: #fff;
}

@media screen and (min-width: 1000px) {
  .termo-font {
    font-size: 15px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1700px) {
  .termo-font {
    font-size: 17px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1900px) {
  .termo-font {
    font-size: 18.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2000px) {
  .termo-font {
    font-size: 20.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2200px) {
  .termo-font {
    font-size: 28px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 3000px) {
  .termo-font {
    font-size: 42px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 4500px) {
  .termo-font {
    font-size: 56px !important;
    text-align: justify !important;
  }
}

.img-example {
  width: 100%;
  height: 100%;
}
.container {
  background-color: #f1f1f1;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0vw 3vw;
}

.containerinit {
  height: 100%;
  width: 100%;
}

.container-total {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100%;
  width: 100%;
}

.first {
  height: 100%;
  width: 50%;
  display: none;
  /* background-image: unset !important; */
}
.second {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .image-firts {
    max-width: 700px !important;
  }
  .first {
    width: 100%;
    display: block;
    height: 50% !important;
  }
  .second {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media screen and (max-width: 1170px) {
  .container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    width: unset;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: unset !important;
    background-size: unset;
    background-image: unset !important;
  }

  .image-firts {
    max-width: 1170px;
  }

  .first {
    width: 100%;
    display: block;
    height: 50% !important;
    background-color: #f1f1f1;
  }

  .second {
    background-color: #f1f1f1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 50%;
    width: 100%;
    padding-left: 150px;
  }

  .container-total {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: unset;
    width: unset;
  }

  /* .container-content {
    width: unset;
  } */
  .content-quiz {
    -webkit-flex-direction: unset;
            flex-direction: unset;
    padding-right: unset;
  }
}

.content-quiz-new {
  /* position: relative; */
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  padding-right: unset !important;
  /* left: 60%; */
  /* padding-right: 12%; */
}

.content-grid-new {
  /* position: absolute; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* max-width: 350px;
  width: 100%;
  height: 100%;
  justify-content: center; */
}

.container-content-new {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 466px;

  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

.container-squad-new {
  background-color: #009de0;
  border-radius: 10px;
  max-height: 595px;
  padding: 30px 30px 30px 30px;
}

/* .MuiInputLabel-outlined {
  transform: translate(14px, 13px) scale(1) !important;
}

.MuiButton-containedSecondary {
  background-color: #0c8bbf !important;
}

.MuiInputBase-input {
  height: 4px !important;
} */

.MuiInputLabel-outlined .MuiInputLabel-marginDense {
  color: #fff !important;
}

.MuiInputBase-root {
  font-size: unset;
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
} */

/* 
.MuiInputBase-input {
  height: 0px !important;

} */

.MuiInput-underline {
  content: none !important;
  right: none !important;
  bottom: none !important;
}
.MuiInput-underline:after {
  content: none !important;
  right: none !important;
  bottom: none !important;
}

.MuiInput-underline:before {
  content: none !important;
  right: none !important;
  bottom: none !important;
}

.termo-new {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  outline: none;
}

.container-new {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: unset !important;
  /* padding: 0vw 3vw; */
}

.termo-font-new {
  font-size: 13.5px !important;
  text-align: justify !important;
}

.title-squad-new {
  max-width: unset !important;
  font-size: 18px;
  font-weight: 900;
  /* font-weight: bold; */
  color: #fff;
  padding-bottom: 10px;
  text-align: left;
}

.name-span-new {
  margin-left: 5px;
  margin-right: 5px;
}

.content-btn-new {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
}

.down-new {
  width: 100% !important;
  background-color: #f3b409 !important;
}

.btn-down-new {
  max-width: unset !important;
  width: 100% !important;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.span-btn-new {
  color: #fff;
}

/* @media screen and (min-width: 1000px) {
  .termo-font {
    font-size: 15px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1700px) {
  .termo-font {
    font-size: 17px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1900px) {
  .termo-font {
    font-size: 18.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2000px) {
  .termo-font {
    font-size: 20.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2200px) {
  .termo-font {
    font-size: 28px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 3000px) {
  .termo-font {
    font-size: 42px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 4500px) {
  .termo-font {
    font-size: 56px !important;
    text-align: justify !important;
  }
} */

.img-example-new {
  width: 100%;
  height: 100%;
}

.container-new {
  background-color: #f1f1f1;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0vw 3vw;
}

.containerinit-new {
  height: 100%;
  width: 100%;
}

.container-total-new {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.first-new {
  height: 100%;
  width: 50%;
  display: none;
}
.second-new {
  height: 100%;
  width: 100%;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  filter: alpha(opacity=65);
  pointer-events: none;
}

.nao {
  width: 105px;
  background: green !important;
}

.sim {
  width: 105px;
  background: red !important;
}

.container-apagar {
  margin-left: 10px;
  margin-top: 40px;
}

.desejo {
  width: 100% !important;
  font-size: 18px;
  font-weight: bold;
}

.container-button {
  margin-top: 8px;
}

.centralizar {
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.teste {
  display: none;
}

.titulo-gerg {
  display: none;
}

.editar {
  background: green !important;
}

.excluir {
  background: red !important;
}

.container-table {
  max-height: 90vh;
  min-height: 77vh;
  width: 100%;
  overflow-x: auto;
}

.header-table label {
  font-size: 20px !important;
  font-weight: bold;
  color: #000;
}

.container-modal {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  outline: none;
}

@media screen and (max-width: 1040px) {
  .container-modal {
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    outline: none;
    overflow: auto;
  }

  .container-table {
    max-height: unset;
    min-height: unset;
  }

  .teste-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .position-left {
    width: 50%;
  }

  .position-right {
    width: 50%;
  }

  .teste {
    display: block !important;
    color: #000000 !important;

    font-weight: bold;
    text-transform: uppercase;
    float: left;
  }

  .teste-value {
    float: right;
  }

  .titulo-gerg {
    display: inline;
  }

  .overview-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 10px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 10px;
    color: #000000 !important;
  }

  .overview-table tr {
    border-bottom: 3px solid #ddd !important;
    display: block !important;
    margin-bottom: 0.625em;
  }

  .overview-table td {
    border-bottom: 1px solid #ddd !important;
    display: block !important;
    font-size: 0.8em;
    text-align: right;
  }
}

.modal-nasa {
  width: 100%;
}

.header {
  height: 45px;
  background: linear-gradient(145deg, #1976d2 11%, #0f477e 75%);
}

.menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.list-item {
  margin-left: 10px;
  margin-top: -3px;
}

.corpo {
  width: 300px;
  height: 60px;
  background: #eaf4fb;
}

