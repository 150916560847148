.button-previous {
  width: 50%;
  background-color: #1976d2 !important;
  border-radius: 0px !important;
}

.button-next {
  border-radius: 0px !important;
  width: 50%;
  background-color: #1976d2 !important;
}

.buttons {
  width: 100%;
  /* margin-top: 10px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.toolbar-video {
  width: 100%;
  height: 100%;
}
.header {
  padding-left: unset !important;
  padding-right: unset !important;
}
.logo-gergo {
  height: 65px;
  margin-top: 8px;
  margin-left: 45px;
}
.logo-gergo-app {
  display: none;
}

.youtube-icon {
  font-size: 30px !important;
}
.icon-video {
  margin-right: 20px;
  margin-top: 5px;
}

.title-video {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 30px;
  margin-bottom: 5px;
  font-family: arial, helvetica, sans-serif;
  font-style: italic;
  /* font-variant: small-caps; */
  font-weight: bold;

  /* font: italic small-caps bold 30px "Comic Sans MS", sans-serif; */
}

.container-videos {
  /* margin-top: 5%; */
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.disposition-video {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.react-player {
  max-width: 720px;
  max-height: 420px;
}

@media screen and (max-width: 983px) {
  .container-videos {
    margin-top: unset;
    align-items: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: unset;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }
  .react-player {
    max-width: 640px !important;
    max-height: 362px !important;
  }
}

/* media queris max-width de 720 a 420*/

@media screen and (max-width: 870px) {
  .container-videos {
    margin-top: unset;
    align-items: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: unset;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }
  .react-player {
    max-width: 550px !important;
    max-height: 314px !important;
  }
}

/* @media screen and (max-width: 683px) {
  .container-videos {
    margin-top: unset;
    align-items: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: unset;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }
  .react-player {
    max-width: 550px;
    max-height: 314px;
  }
} */

@media (min-width: 600px) {
  .header {
    padding-left: unset !important;
    padding-right: unset !important;
  }
}

@media screen and (max-width: 760px) {
  .container-videos {
    margin-top: unset;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }
  .react-player {
    max-width: 490px !important;
    max-height: 279px !important;
  }
}

@media screen and (max-width: 680px) {
  .container-videos {
    margin-top: unset;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }
  .react-player {
    max-width: 468px !important;
    max-height: 267px !important;
  }
}

@media screen and (max-width: 660px) {
  .toolbar-video {
    margin-left: -19px;
  }
  .logo-gergo {
    display: none;
  }
  .logo-gergo-app {
    display: block;
    height: 60px;
    margin-top: -2px;
  }
  .header {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .container-videos {
    margin-top: unset;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }

  .react-player {
    max-width: 420px !important;
    max-height: 239px !important;
  }
}

@media screen and (max-width: 490px) {
  .toolbar-video {
    margin-left: -19px;
  }
  .logo-gergo {
    display: none;
  }
  .logo-gergo-app {
    display: block;
    height: 60px;
    margin-top: -2px;
  }
  .header {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .container-videos {
    margin-top: unset;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 0px;
  }

  .youtube-icon {
    font-size: 20px !important;
  }

  .react-player {
    max-width: 350px !important;
    max-height: 200px !important;
  }
}

/* fim media queris max-width de 720 a 420*/

/*media queris min-width 1000*/

@media screen and (min-width: 1700px) {
  .container-videos {
    margin-top: 3%;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 30px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 5px;
  }

  .youtube-icon {
    font-size: 30px !important;
  }

  .react-player {
    max-width: 1055px;
    max-height: 596px;
  }
}

@media screen and (min-width: 1900px) {
  .container-videos {
    margin-top: 3%;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 30px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 5px;
  }

  .youtube-icon {
    font-size: 30px !important;
  }

  .react-player {
    max-width: 1137px;
    max-height: 643px;
  }
}

@media screen and (min-width: 2000px) {
  .container-videos {
    margin-top: 3%;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 35px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 5px;
  }

  .youtube-icon {
    font-size: 35px !important;
  }

  .react-player {
    max-width: 1310px;
    max-height: 740px;
  }
}

@media screen and (min-width: 2200px) {
  .container-videos {
    margin-top: 3%;
  }
  .title-video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 40px;
    margin-bottom: 5px;
  }

  .icon-video {
    margin-right: 12px;
    margin-top: 5px;
  }

  .youtube-icon {
    font-size: 40px !important;
  }

  .react-player {
    max-width: 1793px;
    max-height: 1012px;
  }
}

/* @media screen and (min-width: 3000px) {
  .termo-font {
    font-size: 42px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 4500px) {
  .termo-font {
    font-size: 56px !important;
    text-align: justify !important;
  }
} */

/*fim min-width 4500*/
