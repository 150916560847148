.content-quiz-new {
  /* position: relative; */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding-right: unset !important;
  /* left: 60%; */
  /* padding-right: 12%; */
}

.content-grid-new {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  /* max-width: 350px;
  width: 100%;
  height: 100%;
  justify-content: center; */
}

.container-content-new {
  display: flex;
  flex-direction: column;
  max-width: 466px;

  width: 100%;
  height: 100%;
  justify-content: center;
}

.container-squad-new {
  background-color: #009de0;
  border-radius: 10px;
  max-height: 595px;
  padding: 30px 30px 30px 30px;
}

/* .MuiInputLabel-outlined {
  transform: translate(14px, 13px) scale(1) !important;
}

.MuiButton-containedSecondary {
  background-color: #0c8bbf !important;
}

.MuiInputBase-input {
  height: 4px !important;
} */

.MuiInputLabel-outlined .MuiInputLabel-marginDense {
  color: #fff !important;
}

.MuiInputBase-root {
  font-size: unset;
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
} */

/* 
.MuiInputBase-input {
  height: 0px !important;

} */

.MuiInput-underline {
  content: none !important;
  right: none !important;
  bottom: none !important;
}
.MuiInput-underline:after {
  content: none !important;
  right: none !important;
  bottom: none !important;
}

.MuiInput-underline:before {
  content: none !important;
  right: none !important;
  bottom: none !important;
}

.termo-new {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.container-new {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: unset !important;
  /* padding: 0vw 3vw; */
}

.termo-font-new {
  font-size: 13.5px !important;
  text-align: justify !important;
}

.title-squad-new {
  max-width: unset !important;
  font-size: 18px;
  font-weight: 900;
  /* font-weight: bold; */
  color: #fff;
  padding-bottom: 10px;
  text-align: left;
}

.name-span-new {
  margin-left: 5px;
  margin-right: 5px;
}

.content-btn-new {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.down-new {
  width: 100% !important;
  background-color: #f3b409 !important;
}

.btn-down-new {
  max-width: unset !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.span-btn-new {
  color: #fff;
}

/* @media screen and (min-width: 1000px) {
  .termo-font {
    font-size: 15px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1700px) {
  .termo-font {
    font-size: 17px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 1900px) {
  .termo-font {
    font-size: 18.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2000px) {
  .termo-font {
    font-size: 20.5px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 2200px) {
  .termo-font {
    font-size: 28px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 3000px) {
  .termo-font {
    font-size: 42px !important;
    text-align: justify !important;
  }
}

@media screen and (min-width: 4500px) {
  .termo-font {
    font-size: 56px !important;
    text-align: justify !important;
  }
} */
