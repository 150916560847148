.img-example {
  width: 100%;
  height: 100%;
}
.container {
  background-color: #f1f1f1;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0vw 3vw;
}

.containerinit {
  height: 100%;
  width: 100%;
}

.container-total {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.first {
  height: 100%;
  width: 50%;
  display: none;
  /* background-image: unset !important; */
}
.second {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .image-firts {
    max-width: 700px !important;
  }
  .first {
    width: 100%;
    display: block;
    height: 50% !important;
  }
  .second {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media screen and (max-width: 1170px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: unset;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: unset !important;
    background-size: unset;
    background-image: unset !important;
  }

  .image-firts {
    max-width: 1170px;
  }

  .first {
    width: 100%;
    display: block;
    height: 50% !important;
    background-color: #f1f1f1;
  }

  .second {
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 100%;
    padding-left: 150px;
  }

  .container-total {
    display: flex;
    flex-direction: column;
    height: unset;
    width: unset;
  }

  /* .container-content {
    width: unset;
  } */
  .content-quiz {
    flex-direction: unset;
    padding-right: unset;
  }
}
