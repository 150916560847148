.CalendarDay__selected {
  background-color: #CCCCCC;
  border: 1px solid #CCCCCC;
  color: #333;
  font-weight: bold;
}

.CalendarDay__selected:hover {
  background-color: #E1E1E1;
  border: 1px solid #E1E1E1;
  color: #333;
  font-weight: bold;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #000;
}
