.video-mobile {
  display: none;
}

.img-example {
  width: 100%;
  height: 100%;
}

.menu-lateral {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  width: 26vw;
  height: 80vh;
}

.container-video {
  display: flex;
  flex-direction: row;
}

.group-video {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 10vh;
}

.menu-item {
  display: flex;
  flex-direction: row;
}

.list-item {
  margin-left: 10px;
  margin-top: -3px;
}

.list-menu {
  width: 23vw;
}

@media screen and (max-width: 680px) {
  .menu-lateral {
    width: 10vw;
    height: 55vh;
  }
  .list-item {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .menu-lateral {
    display: none;
  }
  .list-item {
    display: block;
  }

  .menu-item {
    margin-top: 30px !important;
  }

  .video-not-mobile {
    display: none;
  }

  .video-mobile {
    display: block;
  }

  .video-disabled {
    pointer-events: none;

    /* for "disabled" effect */
    opacity: 0.5;
    background: #ccc;
  }

  .video-not-disabled {
    display: block;
  }
}
