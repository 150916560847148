svg > g[class^="raphael-group-"] > text{
  display: none;
}

#donut {
  margin-top: -15px !important;
  margin-left: -40px !important;
}

#donut-mobile {
  margin-top: -30px !important;
  /* margin-left: -7px !important; */
}