.img-example-new {
  width: 100%;
  height: 100%;
}

.container-new {
  background-color: #f1f1f1;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0vw 3vw;
}

.containerinit-new {
  height: 100%;
  width: 100%;
}

.container-total-new {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.first-new {
  height: 100%;
  width: 50%;
  display: none;
}
.second-new {
  height: 100%;
  width: 100%;
}
