.loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  filter: alpha(opacity=65);
  pointer-events: none;
}

.nao {
  width: 105px;
  background: green !important;
}

.sim {
  width: 105px;
  background: red !important;
}

.container-apagar {
  margin-left: 10px;
  margin-top: 40px;
}

.desejo {
  width: 100% !important;
  font-size: 18px;
  font-weight: bold;
}

.container-button {
  margin-top: 8px;
}

.centralizar {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.teste {
  display: none;
}

.titulo-gerg {
  display: none;
}

.editar {
  background: green !important;
}

.excluir {
  background: red !important;
}

.container-table {
  max-height: 90vh;
  min-height: 77vh;
  width: 100%;
  overflow-x: auto;
}

.header-table label {
  font-size: 20px !important;
  font-weight: bold;
  color: #000;
}

.container-modal {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  outline: none;
}

@media screen and (max-width: 1040px) {
  .container-modal {
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    outline: none;
    overflow: auto;
  }

  .container-table {
    max-height: unset;
    min-height: unset;
  }

  .teste-container {
    display: flex;
    flex-direction: row;
  }

  .position-left {
    width: 50%;
  }

  .position-right {
    width: 50%;
  }

  .teste {
    display: block !important;
    color: #000000 !important;

    font-weight: bold;
    text-transform: uppercase;
    float: left;
  }

  .teste-value {
    float: right;
  }

  .titulo-gerg {
    display: inline;
  }

  .overview-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 10px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 10px;
    color: #000000 !important;
  }

  .overview-table tr {
    border-bottom: 3px solid #ddd !important;
    display: block !important;
    margin-bottom: 0.625em;
  }

  .overview-table td {
    border-bottom: 1px solid #ddd !important;
    display: block !important;
    font-size: 0.8em;
    text-align: right;
  }
}
