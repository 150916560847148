.header {
  height: 45px;
  background: linear-gradient(145deg, #1976d2 11%, #0f477e 75%);
}

.menu-item {
  display: flex;
  flex-direction: row;
}

.list-item {
  margin-left: 10px;
  margin-top: -3px;
}
