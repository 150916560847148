/* .player-wrapper {
    position: relative;
    padding-top: 56.25%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
} */

.react-player {
    max-width: 720px;
    max-height: 420px;
}

@media screen and (max-width: 719px) {
    .react-player {
        max-width: 520px;
        max-height: 350px;
    }   
}

@media screen and (max-width: 519px) {
    .react-player {
        max-width: 420px;
        max-height: 290px;
    }   
}

@media screen and (max-width: 419px) {
    .react-player {
        max-width: 350px;
        max-height: 200px;
    }   
}